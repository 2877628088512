import { graphql, Link, useStaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import get from 'lodash.get'
import React, { useEffect, useState } from 'react'
import { X } from 'react-feather'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Responsive from '../components/responsive'
import Title from '../components/titles'
import LogoSVG from '../images/eneance/logo-eneance-on-dark.svg'
import { actions } from '../redux'
import colors from '../theme/colors'
import media from '../theme/media-queries'

const SiteMenu = ({ menuOpen, toggleMenu }) => {
    const data = useStaticQuery(graphql`
        query MainNavigation {
            prismicMainNavigation {
                data {
                    home_title
                    home_href
                    navigation_items {
                        title
                        href
                    }
                }
            }
            prismicFooter {
                data {
                    address_street
                    address_postal_code
                    address_locality
                    contact_phone
                    address_google_maps_url {
                        url
                    }
                }
            }
        }
    `)
    const { navigation_items, home_href } = get(data, 'prismicMainNavigation.data')

    const { address_street, address_postal_code, address_locality, contact_phone, address_google_maps_url } = get(
        data,
        'prismicFooter.data'
    )

    const closeMenu = () => toggleMenu(false)

    const [scrollY, setScrollY] = useState(0)

    useEffect(() => {
        if (menuOpen) {
            if (document.body.style.position !== 'fixed') {
                setScrollY(window.scrollY)
            }
            document.body.style.position = 'fixed'
        } else {
            document.body.style.position = ''
            window.scrollTo(0, scrollY)
        }
    }, [menuOpen, scrollY])

    return (
        <Modal className="modal" overlayClassName="modal--overlay" isOpen={menuOpen}>
            <MenuContainer>
                <MenuHeader>
                    <Responsive>
                        <Link onClick={() => toggleMenu(false)} to={home_href}>
                            <Logo />
                        </Link>
                        <Close onClick={closeMenu} />
                    </Responsive>
                </MenuHeader>
                <MenuNavigation>
                    <Responsive>
                        <MenuNavigationItems>
                            {navigation_items.map(({ href, title }, index) => {
                                return (
                                    <MenuNavigationItem key={index}>
                                        <Link onClick={() => toggleMenu(false)} to={href}>
                                            <Title size="large">{title}</Title>
                                        </Link>
                                    </MenuNavigationItem>
                                )
                            })}
                        </MenuNavigationItems>
                    </Responsive>
                </MenuNavigation>
                <MenuFooter>
                    <Responsive>
                        <p>
                            <OutboundLink href={get(address_google_maps_url, 'url')} target="_blank" rel="noopener noreferrer">
                                {address_street} {address_postal_code} {address_locality}
                            </OutboundLink>
                            <br />
                            <a href={`tel:${contact_phone}`}>{contact_phone}</a>
                        </p>
                    </Responsive>
                </MenuFooter>
            </MenuContainer>
        </Modal>
    )
}

const MenuContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`

const MenuHeader = styled.div`
    position: absolute;
    top: 0;
    width: 100%;
    padding: 1rem 0;
    ${Responsive} {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
`

const MenuNavigation = styled.nav`
    padding: 2rem 0 0;
    width: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

const MenuNavigationItems = styled.ul`
    list-style-type: none;
`

const MenuNavigationItem = styled.li`
    margin: 0 0 1rem 0;
    padding-bottom: 1rem;
    :not(:last-of-type) {
        border-bottom: solid 0.5px ${colors.white50};
    }
    ${media.phone`
        margin-bottom: 0.8rem;
        padding-bottom: 0.8rem;
    `}
    ${Title} {
        ${media.phone`
            font-size: 1.2rem;
        `}
    }
`

const MenuFooter = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 1rem 0;
    font-size: smaller;
`

const Close = styled(X)`
    width: 2rem;
    height: 2rem;
`

const Logo = styled(LogoSVG)`
    width: 100px;
    height: 102px;
`

const mapStateToProps = (state) => {
    return {
        menuOpen: state.ui.menuOpen,
    }
}

const mapDispatchToProps = (dispatch) => ({
    toggleMenu: (b) => dispatch(actions.ui.toggleMenu(b)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SiteMenu)
