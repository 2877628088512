import { graphql, Link, useStaticQuery } from 'gatsby'
import get from 'lodash.get'
import PropTypes from 'prop-types'
import React from 'react'
import { Menu } from 'react-feather'
import Modal from 'react-modal'
import { connect } from 'react-redux'
import Scrollspy from 'react-scrollspy'
import styled, { css } from 'styled-components'
import Responsive from '../components/responsive'
import Title from '../components/titles'
import MiniLogoSVG from '../images/eneance/logo-small-eneance-on-dark.svg'
import { actions } from '../redux'
import colors from '../theme/colors'
import media from '../theme/media-queries'

Modal.setAppElement('#___gatsby')

const MiniHeader = ({ forHomePage, toggleMenu, ...rest }) => {
    const data = useStaticQuery(graphql`
        query MiniHeaderQuery {
            prismicMainNavigation {
                data {
                    navigation_items {
                        href
                        title
                        is_homepage_anchor
                    }
                }
            }
        }
    `)

    const { navigation_items } = get(data, 'prismicMainNavigation.data')
    const sectionIds = navigation_items
        .filter(({ is_homepage_anchor }) => is_homepage_anchor === 'true')
        .map(({ href }) => href.slice(href.indexOf('#') + 1))

    const NavigationItems = forHomePage ? NavigationItemsScrollSpy : NavigationItemsUl
    const navigationItemsProps = forHomePage
        ? {
              items: sectionIds,
              currentClassName: 'active',
              offset: -100,
          }
        : {}

    return (
        <>
            <MiniHeaderContainer {...rest}>
                <Responsive>
                    <MiniHeaderNavigation>
                        <ImageLink to="/#" title="Énéance">
                            <LogoEneance />
                        </ImageLink>
                        <BurgerMenu size={32} onClick={() => toggleMenu(true)} />
                        <NavigationItems {...navigationItemsProps}>
                            {navigation_items.map(({ href, title }, index) => {
                                return (
                                    <NavigationItem key={index}>
                                        <NavigationItemLink to={href} activeClassName="active" partiallyActive={true}>
                                            <Title>{title}</Title>
                                        </NavigationItemLink>
                                    </NavigationItem>
                                )
                            })}
                        </NavigationItems>
                    </MiniHeaderNavigation>
                </Responsive>
            </MiniHeaderContainer>
        </>
    )
}

const ImageLink = styled(Link)`
    font-size: 0;
`

const BurgerMenu = styled(Menu)`
    cursor: pointer;
    ${media.desktop`
        display: none;
    `}
`

const navigationItemsStyle = css`
    flex: 1;
    padding: 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ${media.laptop`
        display: none;
    `}
`
const NavigationItemsUl = styled.ul`
    ${navigationItemsStyle}
`

const NavigationItemsScrollSpy = styled(Scrollspy)`
    ${navigationItemsStyle}
`

const navigationItemActiveStyle = css`
    & :after {
        background-color: ${colors.vividRed};
        transform: translateX(100%);
        width: 50%;
        opacity: 1;
    }
`

const NavigationItemLink = styled(Link)`
    display: inline-block;
    position: relative;
    margin-left: 1.5rem;
    overflow: hidden;
    & :after {
        content: '';
        transition: all 0.2s ease;
        display: inline-block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 4px;
        height: 2px;
        border-radius: 2px;
        background-color: rebeccapurple;
        transform: translateX(-100%);
        opacity: 0;
    }

    &.active,
    & :hover {
        ${navigationItemActiveStyle}
    }

    ${(props) => props.active && navigationItemActiveStyle}
`

const NavigationItem = styled.li`
    margin: 0;
    list-style-type: none;
    &.active ${NavigationItemLink} {
        ${navigationItemActiveStyle}
    }
`

const LogoEneance = styled(MiniLogoSVG)`
    width: 2.5rem;
    height: 2.5rem;
`

const MiniHeaderNavigation = styled.nav`
    display: flex;
    color: white;
    font-size: 1.125rem;
    padding: 0.5rem 0;
    transition: all 0.2s ease-in-out;
    justify-content: space-between;
    align-items: center;
`

const MiniHeaderContainer = styled.header`
    position: fixed;
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    background-color: ${colors.darkBlue};
    min-height: 2rem;
    max-height: 3.5rem;
`

MiniHeader.propTypes = {
    forHomePage: PropTypes.bool,
}

const mapDispatchToProps = (dispatch) => ({
    toggleMenu: (b) => dispatch(actions.ui.toggleMenu(b)),
})

export default connect(null, mapDispatchToProps)(MiniHeader)
