import { css } from 'styled-components'

const breakpoints = {
    small: 480,
    tabletPortrait: 768,
    tabletLandscape: 1024,
    large: 1140,
}

const media = {
    phone: (...style) => css`
        @media (max-width: ${breakpoints.small}px) {
            ${css(...style)};
        }
    `,
    tablet: (...style) => css`
        @media (max-width: ${breakpoints.tabletPortrait}px) {
            ${css(...style)};
        }
    `,
    atLeastTablet: (...style) => css`
        @media (min-width: ${breakpoints.tabletPortrait + 1}px) {
            ${css(...style)};
        }
    `,
    laptop: (...style) => css`
        @media (max-width: ${breakpoints.large}px) {
            ${css(...style)};
        }
    `,
    atLeastLaptop: (...style) => css`
        @media (min-width: ${breakpoints.large + 1}px) {
            ${css(...style)};
        }
    `,
    desktop: (...style) => css`
        @media (min-width: ${breakpoints.large + 1}px) {
            ${css(...style)};
        }
    `,
}

export default media
export { breakpoints }
