const colors = {
    darkBlue: '#21294D',
    darkBlue80: 'rgba(33, 41, 77, 0.8)',
    vividRed: '#FF474A',
    beige: '#F4ECE6',
    beige50: 'rgba(244, 236, 230, 0.5)',
    beige80: 'rgba(244, 236, 230, 0.8)',
    white: '#FFFFFF',
    white0: 'rgba(255, 255, 255, 0)',
    white50: 'rgba(255, 255, 255, 0.5)',
    white80: 'rgba(255, 255, 255, 0.8)',
}

export default colors
