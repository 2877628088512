import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import colors from '../theme/colors'
import media from '../theme/media-queries'

const PropValues = {
    size: {
        tiny: 'tiny',
        small: 'small',
        medium: 'medium',
        large: 'large',
        hero: 'hero',
    },
    over: {
        white: 'white',
        light: 'light',
        dark: 'dark',
    },
    textAlign: {
        left: 'left',
        center: 'center',
        right: 'right',
    },
}

const titleStyleHero = css`
    font-size: 3.75rem;
    line-height: 4.375rem;

    ${media.laptop`
        font-size: 2.875rem;
        line-height: 3.75rem;
    `}
    ${media.phone`
        font-size: 1.875rem;
        line-height: 2.5rem;
    `}
`

const titleStyleLarge = css`
    font-size: 2.125rem;
    line-height: 2.5rem;

    ${media.laptop`
        font-size: 1.875rem;
        line-height: 2.5rem;
        `}
    ${media.phone`
        font-size: 1.375rem;
        line-height: 1.875rem;
    `}
`

const titleStyleMedium = css`
    font-size: 1.375rem;
    line-height: 1.875rem;

    ${media.phone`
        font-size: 1.125rem;
        line-height: 1.625rem;
    `}
`

const titleStyleSmall = css`
    font-size: 1.125rem;
    line-height: 1.625rem;

    ${media.phone`
        font-size: 0.875rem;
        line-height: 1.625rem;
    `}
`

const titleStyleTiny = css`
    font-size: 0.8rem;
    line-height: 1.625rem;
`

const Title = styled.span`
    display: block;
    font-family: 'Nunito', 'Arial Rounded MT Bold', 'Helvetica Rounded', sans-serif;
    ${(props) =>
        props.noWrap &&
        css`
            white-space: nowrap;
        `}
    ${(props) => {
        switch (props.size) {
            case PropValues.size.tiny:
                return titleStyleTiny
            case PropValues.size.small:
                return titleStyleSmall
            case PropValues.size.medium:
                return titleStyleMedium
            case PropValues.size.large:
                return titleStyleLarge
            case PropValues.size.hero:
                return titleStyleHero
            default:
                return
        }
    }}

    ${(props) => {
        switch (props.over) {
            case PropValues.over.light:
                return css`
                    color: aliceblue;
                `
            case PropValues.over.dark:
                return css`
                    color: white;
                `
            default:
                return css`
                    color: inherit;
                `
        }
    }}

    ${(props) =>
        props.withDot &&
        css`
            & :after {
                content: '.';
                color: ${colors.vividRed};
                ${(props) =>
                    props.over === PropValues.over.light &&
                    css`
                        color: white;
                    `}
                ${(props) =>
                    props.over === PropValues.over.white &&
                    css`
                        color: ${colors.darkBlue};
                    `}
            }
        `}

    ${(props) =>
        props.dotSymbol &&
        css`
            && :after {
                content: '${props.dotSymbol}';
            }
        `}
    
    ${(props) =>
        props.caps &&
        css`
            text-transform: uppercase;
        `}

    ${(props) =>
        props.bold &&
        css`
            & {
                font-weight: 700;
            }
        `}

    ${(props) =>
        props.textAlign &&
        css`
            text-align: ${props.textAlign};
        `}
`

Title.defaultProps = {
    over: PropValues.white,
    size: PropValues.large,
    textAlign: PropValues.left,
    bold: false,
    withDot: false,
    caps: false,
}

Title.propTypes = {
    over: PropTypes.oneOf(Object.values(PropValues.over)),
    size: PropTypes.oneOf(Object.values(PropValues.size)),
    textAlign: PropTypes.oneOf(Object.values(PropValues.textAlign)),
    bold: PropTypes.bool,
    withDot: PropTypes.bool,
    caps: PropTypes.bool,
}

export default Title
export { PropValues, titleStyleHero, titleStyleLarge, titleStyleMedium, titleStyleSmall, titleStyleTiny }
