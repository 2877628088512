import { graphql, Link, useStaticQuery } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import get from 'lodash.get'
import React from 'react'
import { Mail, Phone } from 'react-feather'
import styled from 'styled-components'
import MiniLogoSvg from '../images/eneance/logo-small-eneance-on-dark.svg'
import Facebook from '../images/icons/facebook.svg'
import Linkedin from '../images/icons/linkedin.svg'
import Twitter from '../images/icons/twitter.svg'
import colors from '../theme/colors'
import media from '../theme/media-queries'
import Responsive, { FlexCol, FlexRow } from './responsive'
import Title from './titles'

const Footer = () => {
    const data = useStaticQuery(graphql`
        query FooterQuery {
            site {
                siteMetadata {
                    titleAccentuated
                }
            }
            prismicFooter {
                data {
                    address_street
                    address_postal_code
                    address_locality
                    address_google_maps_url {
                        url
                    }
                    contact_phone
                    contact_email
                    social_networks {
                        social_network_type
                        social_network_link_title
                        social_network_url {
                            url
                        }
                    }
                    body {
                        ... on PrismicFooterBodyCategories {
                            id
                            primary {
                                nav_section_title
                                nav_section_anchor
                            }
                            items {
                                nav_link_title
                                nav_link_anchor
                            }
                        }
                    }
                }
            }
        }
    `)

    const {
        address_street,
        address_postal_code,
        address_locality,
        address_google_maps_url,
        contact_phone,
        contact_email,
        social_networks,
        body,
    } = get(data, 'prismicFooter.data')

    const eneance = get(data, 'site.siteMetadata.titleAccentuated')

    const contact = {
        address: {
            street: address_street,
            postalCode: address_postal_code,
            locality: address_locality,
            href: get(address_google_maps_url, 'url'),
        },
        phone: contact_phone,
        email: contact_email,
    }

    const SocialNetworkIcon = {
        Facebook,
        Twitter,
        Linkedin,
    }
    const socialNetworkIconProps = {
        width: '32px',
        height: '32px',
    }
    const socialNetworks = social_networks.map((item) => ({
        Icon: SocialNetworkIcon[item.social_network_type],
        title: item.social_network_link_title,
        href: item.social_network_url.url,
    }))

    const footerNavColProps = {
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 'auto',
    }

    const navSections = body.map((item) => {
        const { primary, items } = item
        return {
            title: primary.nav_section_title,
            anchor: primary.nav_section_anchor,
            items: items.map((item) => ({
                title: item.nav_link_title,
                anchor: item.nav_link_anchor,
            })),
        }
    })

    return (
        <FooterContainer>
            <Responsive>
                <FlexRow align="flex-start" gutter="1rem">
                    <FlexCol flexGrow={0} flexBasis="auto">
                        <Link to="/" title="Énéance">
                            <MiniLogo />
                        </Link>
                    </FlexCol>
                    <FlexCol>
                        <FlexRow align="flex-start" spaceAfter="3rem" collapseLaptop>
                            <AddressCol flex={1} flexBasis="auto">
                                <Title size="small" as="h2" bold withDot caps itemProp="name">
                                    <Link to="/">{eneance}</Link>
                                </Title>
                                <Address>
                                    <address>
                                        <OutboundLink
                                            href={get(address_google_maps_url, 'url')}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <span itemProp="streetAddress">{contact.address.street}</span>
                                            <br />
                                            <span itemProp="postalCode">{contact.address.postalCode}</span>
                                            &nbsp;
                                            <span itemProp="addressLocality">{contact.address.locality}</span>
                                        </OutboundLink>
                                    </address>
                                    <p>
                                        <Phone size={16} color={colors.white80} />
                                        &nbsp;
                                        <a href={`tel:${contact.phone}`}>
                                            <span itemProp="telephone">{contact.phone}</span>
                                        </a>
                                        <br />
                                        <Mail size={16} color={colors.white80} />
                                        &nbsp;
                                        <a href={`mailto:${contact.email}`}>
                                            <span itemProp="email">{contact.email}</span>
                                        </a>
                                    </p>
                                    <SocialNetworks>
                                        {socialNetworks.map((socialNetwork, index) => (
                                            <a
                                                key={index}
                                                href={socialNetwork.href}
                                                title={socialNetwork.title}
                                                target="_blank"
                                                rel="noopener noreferrer">
                                                <socialNetwork.Icon {...socialNetworkIconProps} />
                                            </a>
                                        ))}
                                    </SocialNetworks>
                                </Address>
                            </AddressCol>
                            <FlexCol flex={2} flexBasis="auto">
                                <FlexRow as="nav" align="flex-start" justify="flex-start" gutter="4rem" flexWrap collapsePhone>
                                    {navSections.map(({ title, anchor, items }, index) => (
                                        <FlexCol {...footerNavColProps} key={index}>
                                            <Link to={anchor}>
                                                <Title size="tiny" as="h3" bold caps noWrap>
                                                    {title}
                                                </Title>
                                            </Link>
                                            <FooterNavItems>
                                                {items.map(({ title, anchor }, index) => (
                                                    <li key={index}>
                                                        <Link to={anchor}>{title}</Link>
                                                    </li>
                                                ))}
                                            </FooterNavItems>
                                        </FlexCol>
                                    ))}
                                </FlexRow>
                            </FlexCol>
                        </FlexRow>
                        <Title size="tiny" as="p">
                            <Link to="/">© {eneance}</Link> {new Date().getFullYear()} -{' '}
                            <Link to="/mentions-legales/">Mentions légales</Link>
                        </Title>
                    </FlexCol>
                </FlexRow>
            </Responsive>
        </FooterContainer>
    )
}

const FooterContainer = styled.footer`
    color: white;
    background-color: ${colors.darkBlue};
    padding: 3rem 0 1rem;
    position: relative;
    & a,
    a:link {
        color: inherit;
        text-decoration: none;
        @media (pointer: coarse) {
            display: inline-block;
            min-height: 48px;
            min-width: 48px;
        }
    }
`

const MiniLogo = styled(MiniLogoSvg)`
    width: 4rem;
    height: 6rem;
`

const AddressCol = styled(FlexCol)`
    ${media.laptop`
        margin-bottom: 2rem;
    `}
`

const Address = styled.div`
    white-space: nowrap;
    ${media.laptop`
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        min-height: 4rem;
        & > *:not(:last-child) {
            margin-right: 2rem;
        }
    `}
`

const SocialNetworks = styled.div`
    & > * {
        color: ${colors.white80};
        fill: white;
        margin-right: 1.4rem;
    }
`

const FooterNavItems = styled.ul`
    color: ${colors.white80};
    list-style-type: none;
    white-space: nowrap;
    li {
        margin-left: 0;
    }
`

export default Footer
