/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import 'reset-css'
import styled, { css } from 'styled-components'
import '../theme/theme.scss'
import Footer from './footer'
import MiniHeader from './mini-header'
import SiteMenu from './site-menu'

if (typeof window !== 'undefined') {
    require('smooth-scroll')('a[href*="#"], a[href*="/#"]', {
        speed: 200,
        speedAsDuration: true,
    })
}
const Layout = ({ withMiniHeader, children }) => {
    return (
        <>
            <Helmet />
            {withMiniHeader && <MiniHeader />}
            <SiteMenu />
            <Main withMiniHeader={withMiniHeader}>{children}</Main>
            <Footer />
        </>
    )
}

const Main = styled.main`
    ${(props) =>
        props.withMiniHeader &&
        css`
            padding-top: 3.5rem;
        `}
`

Layout.propTypes = {
    withMiniHeader: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

export default Layout
