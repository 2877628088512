import styled, { css } from 'styled-components'
import media, { breakpoints } from '../theme/media-queries'

const Responsive = styled.div`
    padding: 0 2rem;
    padding-left: calc(env(safe-area-inset-left) + 1.4rem);
    padding-right: calc(env(safe-area-inset-right) + 1.4rem);
    width: 100%;

    ${media.phone`
        padding: 0 1rem;
        padding-left: calc(env(safe-area-inset-left) + 1rem);
        padding-right: calc(env(safe-area-inset-right) + 1rem);
    `}

    ${media.desktop`
        margin: 0 auto;
        max-width: ${breakpoints.large}px;
        
        ${(props) =>
            props.dockRight &&
            css`
                max-width: 100%;
                overflow-x: scroll;
                padding: 0 0 0 2rem;
                margin: 0 0 0 calc(50% - 570px);
            `}
    `}
`

const FlexResponsive = styled(Responsive)`
    display: flex;
`

const FlexRow = styled.div`
    display: flex;
    align-items: ${(props) => props.align || 'center'};
    justify-content: ${(props) => props.justify || 'flex-start'};
    ${(props) =>
        props.flexWrap &&
        css`
            flex-wrap: wrap;
        `}

    ${(props) =>
        props.spaceAfter &&
        css`
            margin-bottom: ${props.spaceAfter};
        `}

    ${(props) =>
        props.collapseDesktop &&
        css`
            ${media.desktop`
            display: block;
            & > ${FlexCol} {
                margin-right: 0;
            }
        `}
        `}
    ${(props) =>
        props.collapseLaptop &&
        css`
            ${media.laptop`
            display: block;
            && > ${FlexCol} {
                margin-right: 0;
            }
        `}
        `}
    ${(props) =>
        props.collapseTablet &&
        css`
            ${media.tablet`
            display: block;
            && > ${FlexCol} {
                margin-right: 0;
            }
        `}
        `}
    ${(props) =>
        props.collapsePhone &&
        css`
            ${media.phone`
            display: block;
            && > ${FlexCol} {
                margin-right: 0;
            }
        `}
        `}

    ${(props) =>
        props.gutter &&
        css`
            ${FlexCol} {
                margin-right: ${props.gutter};
            }
        `}
`

const FlexCol = styled.div`
    flex-grow: ${(props) => props.flex || 1};
    flex-shrink: inherit;
    flex-basis: 0;

    ${(props) =>
        props.flexGrow !== undefined &&
        css`
            flex-grow: ${props.flexGrow};
        `}

    ${(props) =>
        props.flexShrink !== undefined &&
        css`
            flex-shrink: ${props.flexShrink};
        `}
            
    ${(props) =>
        props.flexBasis !== undefined &&
        css`
            flex-basis: ${props.flexBasis};
        `}

    margin-right: 2rem;

    &:nth-last-child(1) {
        margin-right: 0;
    }

    ${media.desktop`
        ${(props) =>
            props.flexDesktop &&
            css`
                flex: ${props.flexDesktop.toFixed(2)};
            `}
    `}
    ${media.laptop`
        ${(props) =>
            props.flexLaptop &&
            css`
                flex: ${props.flexLaptop.toFixed(2)};
            `}
    `}
    ${media.tablet`
        ${(props) =>
            props.flexTablet &&
            css`
                flex: ${props.flexTablet.toFixed(2)};
            `}
    `}
    ${media.phone`
        ${(props) =>
            props.flexPhone &&
            css`
                flex: ${props.flexPhone.toFixed(2)};
            `}
    `}
`

const ResponsiveImage = styled.div`
    font-size: 0;
    > img {
        width: 100%;
        height: auto;
    }

    ${(props) =>
        props.width &&
        css`
            width: ${props.width};
        `}
    ${(props) =>
        props.height &&
        css`
            height: ${props.height};
        `}
    ${(props) =>
        props.borderRadius &&
        css`
            border-radius: ${props.borderRadius};
            overflow: hidden;
            /* > * {
                border-radius: ${props.borderRadius};
            } */
        `}
`

export default Responsive
export { FlexCol, FlexRow, FlexResponsive, ResponsiveImage }
